import { InlineNotification, InlineNotificationType } from '@shared/inline-notification/inline-notification.component';

import { BehaviorSubject } from 'rxjs';
import { ErrorType } from '@core/enum/error-type.enum';
import { GraphQLErrors } from '@apollo/client/errors';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { globalConfig } from '@environments/configs/globalConfig';
import { last } from 'lodash';

export interface ErrorNotification {
	type: ErrorType;
	message: string;
	optionalMessage?: string;
	title: string;
	preventModalClosing?: boolean;
}

@Injectable({ providedIn: 'root' })
export class ErrorStoreService {
	constructor(private userService: UserService) { }
	private errors = new BehaviorSubject<ErrorNotification>({
		type: ErrorType.NONE,
		message: '',
		title: '',
	});

	private inlineNotification = new BehaviorSubject<InlineNotification>({
		isVisible: false,
		type: InlineNotificationType.WARN,
		text: '',
		data: [],
	});

	errorState$ = this.errors.asObservable();
	inlineNotificationState$ = this.inlineNotification.asObservable();

	getError() {
		return this.errors.value;
	}
	closeError(): void {
		this.errors.next({ type: ErrorType.NONE, message: '', title: '' });
	}

	setUnauthorizedError(): void {
		this.errors.next({
			type: ErrorType.RESTRICTED_ACCESS,
			message: 'SHARED.ERROR.AUTHORIZATION_MESSAGE',
			title: 'SHARED.ERROR.AUTHORIZATION_TITLE',
		});
	}

	setNetworkError(error: HttpErrorResponse): void {
		const UNAUTHORIZED = 401;
		if (error.status === UNAUTHORIZED) {
			this.errors.next({
				type: ErrorType.AUTHORIZATION_FAILED,
				title: 'SHARED.ERROR.401_TITLE',
				message: 'SHARED.ERROR.401_MESSAGE',
				preventModalClosing: true,
			});
		}
	}

	extractPartnerIdsFromErrors(errors: GraphQLErrors): string[] {
		const partnerIdPattern = /^[A-Za-z0-9-]+$/;
		return errors
			.map((error) => {
				if (error?.message && typeof error.message === 'string') {
					// Extract the partner ID from the message
					const parts = error.message.split(' ');
					const lastPart = parts[parts.length - 1];

					// Check if the last part matches the Partner ID pattern
					if (partnerIdPattern.test(lastPart)) {
						return lastPart; // Return if it's a valid Partner ID
					}
				}
				return [];
			})
			.flat()
			.filter(Boolean);
	}

	setNoPartnerFoundGraphQLError(error: GraphQLErrors): void {
		const partnerIds = this.extractPartnerIdsFromErrors(error);
		const isVisible = true;
		const type = InlineNotificationType.WARN;
		const hasAdminRole = this.userService.hasAdminRole();
		const text = hasAdminRole ? 'SHARED.INLINE_NOTIFICATION#ADMIN_PARTNER_NOT_FOUND' : 'SHARED.INLINE_NOTIFICATION#PARTNER_NOT_FOUND';
		const data = partnerIds;

		this.inlineNotification.next({
			isVisible,
			type,
			text,
			data,
		});
	}

	setGraphQLError(error: GraphQLErrors): void {
		let type = ErrorType.DEFAULT;
		let message = '';
		let title = '';

		const separatedError = error[0].message.split(globalConfig.error.separator);
		let optionalMessage = last(separatedError);

		switch (separatedError[0] as unknown as ErrorType) {
			case ErrorType.BAD_REQUEST: {
				type = ErrorType.BAD_REQUEST;
				message = 'SHARED.ERROR.BAD_REQUEST_MESSAGE';
				title = 'SHARED.ERROR.BAD_REQUEST_TITLE';
				break;
			}
			case ErrorType.AUTHORIZATION_FAILED: {
				type = ErrorType.AUTHORIZATION_FAILED;
				message = 'SHARED.ERROR.AUTHORIZATION_FAILED_MESSAGE';
				title = 'SHARED.ERROR.AUTHORIZATION_TITLE';
				break;
			}
			default: {
				optionalMessage = error.map((m) => m.message).join(', ');
				type = ErrorType.DEFAULT;
				message = 'SHARED.ERROR.UNEXPECTED_ERROR_MESSAGE';
				title = 'SHARED.ERROR.UNEXPECTED_ERROR_TITLE';
				break;
			}
		}
		this.errors.next({ type, message, optionalMessage, title });
	}
}
